<template>
    <div class="companylist" v-loading="loading">
        <div class="companylistitem margin-top-xs" v-for="(item,index) in list" :key="index">
            <div class="flex align-center justify-between padding-bottom-xs" style="border-bottom: #f0f0f0 solid 1px;">
                <div class="itemtopleft">
                    <div><strong class="text-xl text-blue">{{ item.workName }}</strong><em class="text-xs text-gray margin-left-xs">{{ item.workEducation }} | {{ item.workYears }}</em></div>
                    <div class="flex align-center margin-top-xs">
                        <!--                        <i class="text-md text-red">8000-12000元</i>-->
                        <span class="text-xs text-gray">{{ item.workAreaDetail }} | {{item.address}}</span>
                    </div>
                </div>
                <div class="itemtopright flex align-center">
                    <span class="text-md">{{ item.companyName }}</span>
                    <!--                    <img src="" style="width: 50px; height: 50px; margin-left: 10px;" alt="">-->
                </div>
            </div>
            <div class="flex align-center justify-between padding-top-xs">
                <div class="flex align-center">
                    <el-tag size="mini" type="info" class="margin-right-xs" v-for="tagitem in item.workWelfare.split(',')">
                        {{ tagitem }}</el-tag>
                </div>
                <div class="text-xs text-gray">{{ item.createTime }}</div>
            </div>
        </div>
        <el-empty v-if="list.length<=0" description="暂无记录"></el-empty>
    </div>
</template>
<script>
import AppPagination from '../../../components/pagination/AppPagination'
import userRequest from '../../../api/user'
export default{
    components: {
        AppPagination
    },
    data(){
        return{

            list:[],
            loading:false,
            searchForm:{
                status:2,
                pageSize: 30,
                pageNum: 1,
            }
        }
    },
    created() {
        this.getList()
    },
    methods:{
        getList(){
            this.loading=true
            userRequest.queryMyDelivery(this.searchForm).then((res) => {
                this.loading=false
                if (res.code === 200) {
                    this.list=res.data.list
                }
            })
        }
    }
}
</script>

<style scoped>
i{ font-style: normal;}
.companylist{}
.companylist em{ font-style: normal;}
</style>
