<template>
    <div>
        <div class="mybox bg-white padding-sm">
            <el-tabs v-model="activeName" >
                <el-tab-pane label="未回复" name="Notreturn"></el-tab-pane>
                <el-tab-pane label="已接受" name="Return"></el-tab-pane>
                <el-tab-pane label="已拒绝" name="Refused"></el-tab-pane>
            </el-tabs>
            <component :is="activeName" />
        </div>
    </div>
</template>
<script>
import Notreturn from "./notReturn"
import Return from './return'
import Refused from './refused'


export default{
    components:{
        Notreturn,
        Return,
        Refused,
    },
    data(){
        return{
            activeName:"Notreturn"
        }
    },
    created() {
    },
    methods:{

    }
}
</script>

<style scoped>
.mybox{ width: 1200px; margin: 15px auto;}
</style>



